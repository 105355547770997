import {CircularProgress, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {object, string} from "prop-types"
import {useEffect, useRef} from "react"
import {MdWarning as WarningIcon} from "react-icons/md"

import ContentContainerReadonly from "components/cross-channel-engagement/content-container-readonly"
import ErrorBoundary from "components/error-boundary/error-boundary"

import useAnalytics from "lib/hooks/use-analytics"

const CrossChannelEngagementFallback = ({classes}) => (
  <Typography className={`page-content ${classes.content} ${classes.error}`} component="div">
    <h5 className={classes.errorHeading}>
      <WarningIcon size={30} /> This channel appears to be broken.
    </h5>
    We're sorry for the inconvenience. This error has been logged.
  </Typography>
)

CrossChannelEngagementFallback.propTypes = {
  classes: object,
}

const CrossChannelEngagement = ({classes, contentContainer, contentContainerId}) => {
  const {track} = useAnalytics()
  const mainRef = useRef(null)
  const channelClasses = useStyles()

  useEffect(() => {
    if (contentContainer) {
      const {contactId, journeyId, page} = contentContainer

      // NB: Including the `contactId` because we won't always have a `journeyId`, i.e. default content container.
      track("cce_presented", {
        contactId,
        journeyId,
        pageId: page?.id,
        contentContainerId: contentContainer.id,
        engagementChannelId: contentContainer.engagementChannelId,
      })
    }
  }, [contentContainer, track])

  if (!contentContainerId)
    return (
      <div className={classes.pageNotFoundWrapper}>
        <Typography align="center" variant="h1">
          404
        </Typography>
        <Typography align="center" variant="subtitle1">
          Channel Not Found
        </Typography>
      </div>
    )

  if (!contentContainer)
    return (
      <div data-testid="cross-channel-engagement-loader" className={channelClasses.loader}>
        <CircularProgress />
      </div>
    )

  return (
    <div className={cx(classes.journeyWrapper, channelClasses.journeyWrapper)}>
      <main className={classes.journey} ref={mainRef}>
        <div className={cx("channel-wrapper", classes.pageWrapper)}>
          <ErrorBoundary
            errorFallback={<CrossChannelEngagementFallback classes={classes} />}
            key={contentContainer.id}
          >
            <style>{styleOverrides}</style>
            <ContentContainerReadonly classes={classes} contentContainer={contentContainer} />
          </ErrorBoundary>
        </div>
      </main>
    </div>
  )
}

CrossChannelEngagement.defaultProps = {
  isEditable: false,
}

CrossChannelEngagement.propTypes = {
  classes: object,
  contentContainer: object,
  contentContainerId: string,
}

const useStyles = makeStyles(theme => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(10),
  },
  journeyWrapper: {
    maxWidth: "none !important",
    padding: "0 !important",
  },
}))

const styleOverrides = `.content-container-content {
    height: 100vh;
    overflow: hidden;
    margin-bottom: 0;
}`

export default CrossChannelEngagement
